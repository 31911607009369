<template>
  <div class="primary fill-height">
    <v-row no-gutters align="stretch" justify="center" class="fill-height">
      <v-col sm="12" md="6" lg="4">
        <vLoginForm />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * Auth view
 * @author Cristian Molina
 */
export default {
  name: "Login",
  components: {
    vLoginForm: () => import("./form/FormSignIn.vue"),
  },
};
</script>
